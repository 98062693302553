import {graphql, Link, useStaticQuery} from "gatsby"
import React from "react"
import headerStyles from "./header.module.scss"
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Img from "gatsby-image";
import styles from "./imageSlider.module.scss";

export default function Header() {
    const data = useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 145) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `)
    return (
        <header className={`header`}>
            <Navbar expand="lg" fixed="top" variant="dark">
                <div className="container">
                    <Navbar.Brand href="/">
                        <Img fixed={data.file.childImageSharp.fixed} alt="logo"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="m-auto d-flex align-items-center">
                            <Link to="/" className={`px-md-3 ${headerStyles.menuItem}`}>
                                Home
                            </Link>
                            <Link to="/#about-us" className={`px-md-3 ${headerStyles.menuItem}`}>
                                About Us
                            </Link>
                            <Link to="/blogs" className={`px-md-3 ${headerStyles.menuItem}`}>
                                Blog
                            </Link>
                            <Link to="/artists-spotlight/" className={`px-md-3 ${headerStyles.menuItem}`}>
                                Artist Spotlight
                            </Link>
                            {/*<a href="deckbliiink.pdf" target="_blank" className={`px-md-3 ${headerStyles.menuItem}`}>*/}
                            {/*    Deck*/}
                            {/*</a>*/}
                            <Link to="/#contact-us" className={`px-md-3 ${headerStyles.menuItem}`}>
                                Contact Us
                            </Link>
                        </Nav>
                        <a target="_blank" href="https://open.bliiink.co.uk/" className={`joinButton ${headerStyles.menuItem} ${headerStyles.joinButton}`}>
                            <button type="button" className={`btn rounded-pill btn-dark ${styles.btn}`}>
                                Join Free
                            </button>
                        </a>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </header>
    )
}
