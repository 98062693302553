import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faTwitter, faYoutube, faDiscord} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import styles from "./socialIcons.module.scss"

export default function SocialIcons() {
    return (
        <>
            <a href="https://twitter.com/bliiinkmusic" target="_blank" rel="noreferrer" className={styles.socialIcons}>
                <FontAwesomeIcon icon={faTwitter}/>
                <svg className={styles.svgcircle}>
                    <circle cx="50%" cy="50%" r="45%"></circle>
                </svg>
            </a>
            <a href="https://www.facebook.com/Bliiink-120068186568746" rel="noreferrer" target="_blank" className={styles.socialIcons}>
                <FontAwesomeIcon icon={faFacebookF}/>
                <svg className={styles.svgcircle}>
                    <circle cx="50%" cy="50%" r="45%"></circle>
                </svg>
            </a>
            <a href="https://instagram.com/bliiinkmusic" target="_blank" rel="noreferrer" className={styles.socialIcons}>
                <FontAwesomeIcon icon={faInstagram}/>
                <svg className={styles.svgcircle}>
                    <circle cx="50%" cy="50%" r="45%"></circle>
                </svg>
            </a>
            <a href="https://www.youtube.com/channel/UCiVF43Km01n4OJru8BusbwQ" target="_blank" rel="noreferrer" className={styles.socialIcons}>
                <FontAwesomeIcon icon={faYoutube}/>
                <svg className={styles.svgcircle}>
                    <circle cx="50%" cy="50%" r="45%"></circle>
                </svg>
            </a>
            <a href="https://discord.gg/6NndvkFWYq" target="_blank" rel="noreferrer" className={styles.socialIcons}>
                <FontAwesomeIcon icon={faDiscord}/>
                <svg className={styles.svgcircle}>
                    <circle cx="50%" cy="50%" r="45%"></circle>
                </svg>
            </a>
        </>
    );
}
