import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import BackgroundImage from "gatsby-background-image";
import Img from 'gatsby-image'
import SocialIcons from "./SocialIcons";
import styles from "./footer.module.scss"
import Link from "gatsby-link";

export default function Footer() {
    const data = useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 220) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        footerBg: file(relativePath: { eq: "BG-5.png" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        footerBorder: file(relativePath: { eq: "BG-4.png" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
      }
    `)

    return (
        <>
            <Img fluid={data.footerBorder.childImageSharp.fluid}/>
            <footer className={styles.bg}>
                <div className="container py-5">
                    <div className="row">
                        <div className="col text-center">
                            <Img fixed={data.file.childImageSharp.fixed} alt="logo" className="footer-logo-img"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <SocialIcons/>
                        </div>
                    </div>
                </div>
                <BackgroundImage
                    Tag="div"
                    fluid={data.footerBg.childImageSharp.fluid}>
                    <div className="container">
                        <div className="row my-2">
                            <div className="col text-center py-5">
                                <p className="mb-0">
                                    2022 @ ALL Rights Reserved
                                </p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-6 text-center">
                                <Link to={'/privacy-policy'}>Privacy Policy</Link>
                            </div>
                            <div className="col-md-6 text-center">
                                <Link to={'/terms-use'}>Terms of use</Link>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            </footer>
        </>
    );
}
